// in src/Expressions.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  SelectInput,
  FileField,
  FileInput,
  BooleanInput,
  ImageField,
  DateField
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const FlagFilter = (props) => (
  <Filter {...props}>
        <TextInput source="reason" alwaysOn />
        <TextInput source="type" alwaysOn />
  </Filter>
);

export const FlagList = (props) => (
  <List {...props} filters={<FlagFilter />}>
    <Datagrid>
    <ReferenceField label="Expression" source="expressionId" reference="expressions">
        <ImageField source="thumbnailUrl" />
      </ReferenceField>
      <ReferenceField label="Reporting User" source="createdBy" reference="users">
        <TextField source="displayName" />
      </ReferenceField>
      <TextField source="reason" />
      <TextField source="type" />
      <ShowButton label="" />
      <DeleteButton label="" redirect={false}/>
    </Datagrid>
  </List>
);

export const FlagShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Expression" source="expressionId" reference="expressions">
        <ImageField source="thumbnailUrl" />
      </ReferenceField>
      <ReferenceField label="Reporting User" source="createdBy" reference="users">
        <TextField source="displayName" />
      </ReferenceField>
      <TextField source="reason" />
      <TextField source="type" />
    </SimpleShowLayout>
  </Show>
);


import * as React from 'react'
import { FlagList, FlagShow } from './flags'
import { CrisisList, CrisisShow, CrisisCreate, CrisisEdit } from './crisisResources'

import { Admin, Resource } from 'react-admin'
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase"
import { LoginPage } from './Login'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { firebaseConfig } from './config'

const options = {
  logging: true,
}
const dataProvider = FirebaseDataProvider(firebaseConfig, options)
const authProvider = FirebaseAuthProvider(firebaseConfig, options)

const App = () => {
    return (
      <Admin
        loginPage={LoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="flags"
          options={{ label: 'Flagged Notes'}}
          list={FlagList}
          show={FlagShow}
        />
        <Resource
          name="crisisResources/anxietyAttack/resources"
          options={{ label: 'Anxiety Attack'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/bullying/resources"
          options={{ label: 'Bullying'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/chronicDepression/resources"
          options={{ label: 'Chronic Depression'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/eatingDisorders/resources"
          options={{ label: 'Eating Disorders'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/grief/resources"
          options={{ label: 'Grief Disorders'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/harmToOthers/resources"
          options={{ label: 'Harm To Others'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/mentalHealth/resources"
          options={{ label: 'Mental Health'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/otherEmergency/resources"
          options={{ label: 'Other Emergency'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/physical/resources"
          options={{ label: 'Physical'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/sexualAssault/resources"
          options={{ label: 'Sexual Assault'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/substanceAbuse/resources"
          options={{ label: 'Substance Abuse'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource
          name="crisisResources/suicidalTendencies/resources"
          options={{ label: 'Suicidal Tendencies'}}
          list={CrisisList}
          show={CrisisShow}
          create={CrisisCreate}
          edit={CrisisEdit}
          icon={HelpOutlineIcon}
        />
        <Resource name="users" />

        <Resource name="expressions" />
      </Admin>
    )
}

export default App

// in src/Crisiss.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  ReferenceField,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  RichTextField,
  FileField,
  UrlField,
  ListButton,
  TopToolbar
} from "react-admin";

import { makeStyles } from '@material-ui/core/styles';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

const styles = makeStyles({
  displayLinebreakAndTab: {"white-space": "pre-wrap" },
});

const LineBreakField = props => {
  const classes = styles();
  return <RichTextField className={classes.displayLinebreakAndTab} {...props} />;
}

const CrisisFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="id" alwaysOn />
  </Filter>
);

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
      <ListButton label="Back" icon={<ChevronLeft />} basePath={basePath} />
  </TopToolbar>
);

export const CrisisList = (props) => (
  <List title={ props.options.label } {...props} filters={<CrisisFilter />}>
    <Datagrid>
      <LineBreakField source="blueMessage" />
      <LineBreakField source="recommendedContentMessage" />
      <LineBreakField source="resourceContent" />
      <UrlField source="resourceLink" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false}/>
    </Datagrid>
  </List>
);

export const CrisisShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField label="Comment" source="title" reference="comments">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="createdate" />
      <TextField source="lastupdate" />
      <RichTextField source="body" />
      <FileField source="file.src" title="file.title" />
    </SimpleShowLayout>
  </Show>
);

export const CrisisCreate = (props) => (
  <Create {...props} >
    <SimpleForm>
      <TextInput multiline fullWidth source="blueMessage" />
      <TextInput multiline fullWidth source="recommendedContentMessage" />
      <TextInput multiline fullWidth source="resourceContent" />
      <TextInput fullWidth source="resourceLink" type="url"/>
    </SimpleForm>
  </Create>
);

export const CrisisEdit = (props) => (
<Edit actions={<EditActions />} title="Edit Resource" {...props}>
    <SimpleForm>
      <TextInput multiline fullWidth source="blueMessage" />
      <TextInput multiline fullWidth source="recommendedContentMessage" />
      <TextInput multiline fullWidth source="resourceContent" />
      <TextInput fullWidth source="resourceLink" type="url"/>
    </SimpleForm>
  </Edit>
);

export const firebaseConfig = {
    apiKey: "AIzaSyBNhAt8VPmUdRqkVj1pifLl9x_3aRcIsqQ",
    authDomain: "shinggg-development.firebaseapp.com",
    databaseURL: "https://shinggg-development.firebaseio.com",
    projectId: "shinggg-development",
    storageBucket: "shinggg-development.appspot.com",
    messagingSenderId: "1022510912116",
    appId: "1:1022510912116:web:c8e1c459b9fa2e6de7b721",
    measurementId: "G-GZCMZL4QBD"
};

